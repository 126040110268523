@import url("https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&display=swap");

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  font-family: "Ubuntu Condensed", sans-serif;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
*::-webkit-scrollbar {
  display: none;
}

body {
  background-color: #c5e4e7;
  color: #00474b;
}
